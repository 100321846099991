/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/main',
      name: 'Main',
      view: 'Main'
    },
    // {
    //   path: '/treasury',
    //   name: 'Treasury',
    //   view: 'Treasury'
    // },
    // {
    //   path: '/inventory',
    //   name: 'Inventory',
    //   view: 'Inventory'
    // },
    // {
    //   path: '/accounts',
    //   name: 'Account Management',
    //   view: 'AccountPage'
    // },
    {
      path: '/transactions',
      name: 'Transactions',
      view: 'Transactions'
    },
    {
      path: '/pendings',
      name: 'Pending Settlements',
      view: 'Pendings'
    },
    // {
    //   path: '/settings',
    //   name: 'Settings',
    //   view: 'SettingsPage'
    // },
    // {
    //   path: '/merchants',
    //   name: 'Merchants',
    //   view: 'Merchants'
    // },
    // {
    //   path: '/guest-finder',
    //   name: 'Guest Finder',
    //   view: 'GuestFinder'
    // }
  ]
  